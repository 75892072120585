import {
  FETCH_COMPLETED_ASSESSMENT_LIST_REQUEST,
  FETCH_COMPLETED_ASSESSMENT_LIST_SUCCESS,
  FETCH_COMPLETED_ASSESSMENT_LIST_FAILURE,
  RESET_COMPLETED_ASSESSMENT_LIST_DATA,
  FETCH_ASSESSMENT_QUESTION_LIST_REQUEST,
  FETCH_ASSESSMENT_QUESTION_LIST_SUCCESS,
  FETCH_ASSESSMENT_QUESTION_LIST_FAILURE,
  FETCH_ONGOING_ASSESSMENT_LIST_REQUEST,
  FETCH_ONGOING_ASSESSMENT_LIST_SUCCESS,
  FETCH_ONGOING_ASSESSMENT_LIST_FAILURE,
  ASSESSMENT_STARTED,
  FETCH_VIEW_ASSESSMENT_ANALYSIS_REQUEST,
  FETCH_VIEW_ASSESSMENT_ANALYSIS_SUCCESS,
  FETCH_VIEW_ASSESSMENT_ANALYSIS_FAILURE
} from "./types";

import {
  getAssessmentListApi,
  getAssessmentQuestionListApi,
  postStartNewAssessmentApi,
  postSubmitAssessmentApi,
  getOngoingAssessmentListApi,
  getPendingAssessmentQuestionListApi,
  getAdminViewAssessmentAnalysisApi,
  postSendEmailAssessmentAnalysisApi
} from "../../services/firmsListofAssessmentServices";
import toast from "react-hot-toast";

export const fetchAssessmentListRequest = () => {
  return {
    type: FETCH_COMPLETED_ASSESSMENT_LIST_REQUEST
  };
};

export const fetchAssessmentListSuccess = (assessmentlist, totalPage) => {
  return {
    type: FETCH_COMPLETED_ASSESSMENT_LIST_SUCCESS,
    payload: { assessmentlist, totalPage }
  };
};

export const fetchAssessmentListFailure = (error) => {
  return {
    type: FETCH_COMPLETED_ASSESSMENT_LIST_FAILURE,
    payload: error
  };
};

export const fetchAssessmentQuestionListRequest = () => {
  return {
    type: FETCH_ASSESSMENT_QUESTION_LIST_REQUEST
  };
};

export const fetchAssessmentQuestionListSuccess = (sectionList) => {
  return {
    type: FETCH_ASSESSMENT_QUESTION_LIST_SUCCESS,
    payload: sectionList
  };
};

export const fetchAssessmentQuestionListFailure = (error) => {
  return {
    type: FETCH_ASSESSMENT_QUESTION_LIST_FAILURE,
    payload: error
  };
};
export const fetchOngoingAssessmentListRequest = () => {
  return {
    type: FETCH_ONGOING_ASSESSMENT_LIST_REQUEST
  };
};

export const fetchOngoingAssessmentListSuccess = (ongoingAssessmentList) => {
  return {
    type: FETCH_ONGOING_ASSESSMENT_LIST_SUCCESS,
    payload: { ongoingAssessmentList }
  };
};

export const fetchOngoingAssessmentListFailure = (error) => {
  return {
    type: FETCH_ONGOING_ASSESSMENT_LIST_FAILURE,
    payload: error
  };
};

export const assessmentStarted = (startSurveyData) => {
  return {
    type: ASSESSMENT_STARTED,
    payload: startSurveyData
  };
};

export const resetAssessmentList = () => {
  return {
    type: RESET_COMPLETED_ASSESSMENT_LIST_DATA
  };
};

export const fetchAssessmentListThunkAction = (params, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAssessmentListRequest());
      const { data } = await getAssessmentListApi(params);

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      dispatch(
        fetchAssessmentListSuccess(data.data.data, data.data.meta.total_records / params.limit)
      );
      onSuccess();
    } catch (error) {
      dispatch(fetchAssessmentListFailure(error));
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const startNewAssessmentThunkAction = (onSuccess, onError) => {
  return async (dispatch) => {
    try {
      const { data } = await postStartNewAssessmentApi();

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      dispatch(assessmentStarted(data?.data));
      onSuccess();
    } catch (error) {
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const fetchAssessmentQuestionListThunkAction = (onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAssessmentQuestionListRequest());
      const { data } = await getAssessmentQuestionListApi();

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      const threeTextTypeAnswer = [
        {
          id: "ans1",
          projectName: "",
          contactNumber: ""
        },
        {
          id: "ans2",
          projectName: "",
          contactNumber: ""
        },
        {
          id: "ans3",
          projectName: "",
          contactNumber: ""
        }
      ];

      const formattedSections = data.data.sections.map((section) => {
        return {
          ...section,
          questions: section?.questions?.map((question) => {
            return {
              ...question,
              answer:
                question?.type === "check_box"
                  ? []
                  : question?.type === "3-text"
                  ? threeTextTypeAnswer
                  : ""
            };
          })
        };
      });

      const sectionAttendedQuestions = data.data.sections.map((section) => {
        return {
          sectionId: section?.section_id,
          totalQuestions: section?.questions?.length,
          attendedQuestions: 0
        };
      });

      dispatch(
        fetchAssessmentQuestionListSuccess({
          sectionList: formattedSections,
          totalSurveyQuestions: data?.data?.total_question,
          sectionAttendedQuestions: sectionAttendedQuestions
        })
      );
      onSuccess();
    } catch (error) {
      dispatch(
        fetchAssessmentQuestionListFailure(error?.response?.data?.message || error?.message)
      );
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const submitAssessmentThunkAction = (postData, onSaved, onError) => {
  return async (dispatch) => {
    try {
      const promise = postSubmitAssessmentApi(postData);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Assessment Saved successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.success !== true) {
        throw new Error(data?.message);
      }
      onSaved();
    } catch (error) {
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const fetchOngoingAssessmentListThunkAction = (onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchOngoingAssessmentListRequest());
      const { data } = await getOngoingAssessmentListApi();

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      dispatch(fetchOngoingAssessmentListSuccess(data.data));
      onSuccess();
    } catch (error) {
      dispatch(fetchOngoingAssessmentListFailure(error));
      onError(error.response?.data?.message || error.message);
    }
  };
};

export const fetchPendingAssessmentQuestionListThunkAction = (id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchAssessmentQuestionListRequest());
      const { data } = await getPendingAssessmentQuestionListApi(id);

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      const threeTextTypeAnswer = [
        {
          id: "ans1",
          projectName: "",
          contactNumber: ""
        },
        {
          id: "ans2",
          projectName: "",
          contactNumber: ""
        },
        {
          id: "ans3",
          projectName: "",
          contactNumber: ""
        }
      ];

      const formattedSections = data.data.sections.map((section) => {
        return {
          ...section,
          questions: section?.questions?.map((question) => {
            if (question?.type === "number") {
              return {
                ...question,
                answer: question?.answer_value ? question?.answer_value : ""
              };
            }

            if (question?.type === "check_box") {
              return {
                ...question,
                answer: question?.answer_index ? question?.answer_index.split(",") : []
              };
            }

            if (question?.type === "3-text") {
              return {
                ...question,
                answer: question?.answer_value
                  ? JSON.parse(question?.answer_value)
                  : threeTextTypeAnswer
              };
            }

            return {
              ...question,
              answer: question?.answer_index ? question?.answer_index : ""
            };
          })
        };
      });

      const sectionAttendedQuestions = data.data.sections.map((section) => {
        return {
          sectionId: section?.section_id,
          totalQuestions: section?.questions?.length,
          attendedQuestions: section?.questions?.filter((item) => "answer_index" in item)?.length
        };
      });

      const surveyDetails = {
        id: data?.data?.survey_report_id,
        start_time: data?.data?.start_time,
        attempt_number: data?.data?.attempt_number,
        company: data?.data?.company
      };

      dispatch(assessmentStarted(surveyDetails));

      dispatch(
        fetchAssessmentQuestionListSuccess({
          sectionList: formattedSections,
          totalSurveyQuestions: data?.data?.total_question,
          sectionAttendedQuestions: sectionAttendedQuestions
        })
      );
    } catch (error) {
      dispatch(
        fetchAssessmentQuestionListFailure(error?.response?.data?.message || error?.message)
      );
    }
  };
};

export const fetchViewAssessmentAnalysisRequest = () => {
  return {
    type: FETCH_VIEW_ASSESSMENT_ANALYSIS_REQUEST
  };
};

export const fetchViewAssessmentAnalysisSuccess = (data) => {
  return {
    type: FETCH_VIEW_ASSESSMENT_ANALYSIS_SUCCESS,
    payload: data
  };
};

export const fetchViewAssessmentAnalysisFailure = (error) => {
  return {
    type: FETCH_VIEW_ASSESSMENT_ANALYSIS_FAILURE,
    payload: error
  };
};

export const fetchViewAssessmentAnalysisThunkAction = (id, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      dispatch(fetchViewAssessmentAnalysisRequest());
      const { data } = await getAdminViewAssessmentAnalysisApi(id);

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }

      dispatch(fetchViewAssessmentAnalysisSuccess(data?.data));
      onSuccess();
    } catch (error) {
      dispatch(
        fetchViewAssessmentAnalysisFailure(error?.response?.data?.message || error?.message)
      );
      onError(error?.response?.data?.message || error?.message);
    }
  };
};

export const sendEmailAssessmentAnalysisThunkAction = (reqBody) => {
  return async (dispatch) => {
    try {
      const promise = postSendEmailAssessmentAnalysisApi(reqBody);

      toast.promise(promise, {
        loading: "Loading...",
        success: "Survey report emailed successfully.",
        error: (error) => `${error?.response?.data?.message || error?.message}`
      });

      const { data } = await promise;

      if (data.statusCode !== 200) {
        throw new Error(data.message);
      }
    } catch (error) {
      console.log(error?.response?.data?.message || error?.message);
    }
  };
};
