import { useRef, useState, useEffect, Fragment } from "react";
import { Button, Card, CardBody, CardHeader, Col, Row, Table } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import HeatMapChart from "components/Charts/HeatMapChart";
import "./AssessmentAnalysis.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchViewAssessmentAnalysisThunkAction } from "redux/firmsListofAssessment/actions";
import { selectAssessmentList } from "redux/firmsListofAssessment/selectors";
import Loader from "components/Loader/Loader";
import { assessmentSectionTitles } from "utils/roles";
import moment from "moment";
import useViewport from "hooks/useViewport";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import useScrollBlock from "hooks/useScrollBlock";
import BackdropLoader from "components/BackdropLoader/BackdropLoader";
import "jspdf/dist/polyfills.es.js";
import { sendEmailAssessmentAnalysisThunkAction } from "redux/firmsListofAssessment/actions";
import { Icon } from "@iconify/react";
import Header from "../../assets/img/theme/header.jpg";
import Footer from "../../assets/img/theme/footer.jpg";
import { getexportpdfData } from "services/firmsListofAssessmentServices";
import toast from "react-hot-toast";
import { getFormattedContact } from "utils/roles";
import { getCompanyRiskBadgeClass } from "utils/roles";
import PDFQuestionTable from "./PDFQuestionTable";
import userRoles from "utils/roles";
import { selectUserRole } from "redux/user/selectors";

function AssessmentAnalysis() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { width } = useViewport();
  const [blockScroll, allowScroll] = useScrollBlock();
  const ref = useRef(null);
  const { id: assessmentId } = useParams();
  const { isLoading, assessmentAnalysisData } = useSelector(selectAssessmentList);
  const role = useSelector(selectUserRole) || localStorage.getItem("role");

  const [error, setError] = useState(null);
  const [data, setData] = useState({});
  const [graphData, setGraphData] = useState(null);
  const [graphTitles, setGraphTitles] = useState(null);
  const [exporting, setExporting] = useState(false);
  const [pdfData, setPdfData] = useState(null);
  const [questionList, setQuestionList] = useState([]);

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  useEffect(() => {
    if (assessmentId) {
      dispatch(fetchViewAssessmentAnalysisThunkAction(assessmentId, onSuccess, onError));
    }
  }, [assessmentId]);

  useEffect(() => {
    const fetchPdfData = async () => {
      try {
        const { data } = await getexportpdfData(assessmentId);
        setPdfData(data?.data);
        const questionListData = data?.data?.survey_section?.map((item) => item.questions).flat();
        setQuestionList(questionListData);
        if (data.success !== true) {
          throw new Error(data?.message);
        }
      } catch (error) {
        toast.error(error.response?.data?.message || error?.message);
      }
    };

    fetchPdfData();
  }, [assessmentId]);

  useEffect(() => {
    setData(assessmentAnalysisData);
    setGraphData(
      assessmentAnalysisData?.survey_sections?.map((item) => item?.normalized_score?.toFixed(2))
    );
    setGraphTitles(
      assessmentAnalysisData?.survey_sections?.map(
        (item) => assessmentSectionTitles[`${item?.section_id}`]
      )
    );
  }, [assessmentAnalysisData]);

  const downloadPdfDocument = () => {
    const divToPrint = ref.current;
    blockScroll();
    divToPrint.style.display = "block";
    setExporting(true);

    setTimeout(() => {
      html2canvas(divToPrint)
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const imgWidth = 190;
          const pageHeight = 297;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;
          const doc = new jsPDF("p", "mm", [210, 297]);
          let position = 10;
          doc.setFontSize(30);
          doc.text("________________________", 35, 155);
          doc.text("Business Assessment Report", 35, 150);
          doc.text("________________________", 35, 135);
          doc.addPage();
          doc.addImage(Header, "PNG", 0, 0, 210, 30, undefined, "FAST");
          doc.addImage(imgData, "PNG", 10, 30, imgWidth, imgHeight, undefined, "FAST");
          doc.addImage(Footer, "PNG", 0, 267, 210, 30, undefined, "FAST");

          let itr = 0;
          heightLeft -= pageHeight - 60;
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            if (itr === 0) {
              doc.addImage(
                imgData,
                "PNG",
                10,
                position + 52,
                imgWidth,
                imgHeight - 42,
                undefined,
                "FAST"
              );
            } else {
              doc.addImage(
                imgData,
                "PNG",
                10,
                position + 60,
                imgWidth,
                imgHeight - 72,
                undefined,
                "FAST"
              );
            }

            heightLeft -= pageHeight - 60;
            itr = itr + 1;
          }

          const pageCount = doc.internal.getNumberOfPages();
          for (var i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.addImage(Header, "PNG", 0, 0, 210, 30, undefined, "FAST");
            doc.addImage(Footer, "PNG", 0, 267, 210, 30, undefined, "FAST");
            doc.setFontSize(10);
            doc.text(String(i) + " / " + String(pageCount), 180, 284, {});
          }

          doc.save("survey_report.pdf");
          divToPrint.style.display = "none";
          setExporting(false);
          allowScroll();
        })
        .catch(() => {
          divToPrint.style.display = "none";
          setExporting(false);
          allowScroll();
        });
    }, 500);
  };

  const senMailPdfDocument = () => {
    const divToPrint = ref.current;
    blockScroll();
    divToPrint.style.display = "block";
    setExporting(true);

    setTimeout(() => {
      html2canvas(divToPrint)
        .then((canvas) => {
          const imgData = canvas.toDataURL("image/png");
          const imgWidth = 190;
          const pageHeight = 297;
          const imgHeight = (canvas.height * imgWidth) / canvas.width;
          let heightLeft = imgHeight;
          const doc = new jsPDF("p", "mm", [210, 297]);
          let position = 10;
          doc.setFontSize(35);
          // doc.setTextColor(255,0,0);
          doc.text("________________________", 35, 155);
          doc.text("Business Assessment Report", 35, 150);
          // doc.setTextColor(255,0,0);
          doc.text("________________________", 35, 135);
          doc.addPage();
          doc.addImage(Header, "PNG", 0, 0, 210, 30, undefined, "FAST");
          doc.addImage(imgData, "PNG", 10, 30, imgWidth, imgHeight, undefined, "FAST");
          doc.addImage(Footer, "PNG", 0, 267, 210, 30, undefined, "FAST");

          let itr = 0;
          heightLeft -= pageHeight - 60;
          while (heightLeft >= 0) {
            position = heightLeft - imgHeight;
            doc.addPage();
            if (itr === 0) {
              doc.addImage(
                imgData,
                "PNG",
                10,
                position + 52,
                imgWidth,
                imgHeight - 42,
                undefined,
                "FAST"
              );
            } else {
              doc.addImage(
                imgData,
                "PNG",
                10,
                position + 60,
                imgWidth,
                imgHeight - 72,
                undefined,
                "FAST"
              );
            }

            heightLeft -= pageHeight - 60;
            itr = itr + 1;
          }
          const pageCount = doc.internal.getNumberOfPages();
          doc.deletePage(pageCount);
          let updatedPageCount = doc.internal.getNumberOfPages();
          for (var i = 1; i <= updatedPageCount; i++) {
            doc.setPage(i);
            doc.addImage(Header, "PNG", 0, 0, 210, 30, undefined, "FAST");
            doc.addImage(Footer, "PNG", 0, 267, 210, 30, undefined, "FAST");
            doc.setFontSize(10);
            doc.text(String(i) + " / " + String(updatedPageCount), 180, 284, {});
          }

          const pdf = doc.output("blob");

          const formData = new FormData();
          formData.append("file", pdf);

          dispatch(sendEmailAssessmentAnalysisThunkAction(formData));

          divToPrint.style.display = "none";
          setExporting(false);
          allowScroll();
        })
        .catch(() => {
          divToPrint.style.display = "none";
          setExporting(false);
          allowScroll();
        });
    }, 500);
  };

  return (
    <>
      {exporting ? <BackdropLoader /> : null}

      <div className="px-3">
        <Row className="justify-content-between align-items-center mx-1">
          <span className="Headers">
            <h4>View analysis</h4>
          </span>
          <Button
            color="primary"
            className="px-3 py-2 back_to_survey_list"
            onClick={() => navigate(-1)}
          >
            <Icon icon="charm:chevron-left" color="white" className="mr-2" />
            Back to Assessment
          </Button>
        </Row>

        {isLoading ? (
          <Fragment>
            <div className="post_error_loading">
              <Loader />
            </div>
          </Fragment>
        ) : (
          <>
            {error ? (
              <Fragment>
                <div className="post_error_loading">{error}</div>
              </Fragment>
            ) : (
              <Fragment>
                <Row className="mt-4">
                  <Col sm={12} className={`content active-content`}>
                    <Card
                      className="card-stats mb-xl-0"
                      style={{
                        borderRadius: "0.5rem"
                      }}
                    >
                      <CardHeader
                        style={{
                          borderRadius: "0.5rem"
                        }}
                      >
                        <Row className="d-flex justify-content-between flex-nowrap">
                          <div className="assessment_analysis_card_title ml-2">
                            Assessment analysis
                          </div>
                          <div
                            className="profile_current_plan_alert mx-2 px-2"
                            style={{
                              width: "inherit"
                            }}
                          >
                            <Icon
                              icon="eva:pie-chart-outline"
                              width="16"
                              height="16"
                              className="mr-2"
                            />
                            The score is out of 10
                          </div>
                        </Row>
                      </CardHeader>

                      <Fragment>
                        <CardBody>
                          <Row className="align-items-center">
                            <Col lg={4} md={12} className="mt-2">
                              <Table className="table-borderless table_font" responsive>
                                {data?.survey_sections?.length
                                  ? data?.survey_sections?.map((item) => {
                                      return (
                                        <tr key={item?.section_id}>
                                          <td className="assessment_analysis_text">
                                            {assessmentSectionTitles[`${item?.section_id}`]}
                                          </td>
                                          <td>:</td>
                                          <td>
                                            <b>{item?.normalized_score?.toFixed(2)}</b>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  : null}
                              </Table>
                            </Col>
                            <Col lg={8} md={12} className="mt-2">
                              {graphData?.length ? (
                                <HeatMapChart
                                  data={graphData}
                                  categories={graphTitles}
                                  width={width < 775}
                                />
                              ) : null}
                            </Col>

                            {data?.survey_sections?.length === 0 ? (
                              <Col sm={12} className="post_error_loading pt-0">
                                No data found.
                              </Col>
                            ) : null}
                          </Row>
                        </CardBody>
                      </Fragment>
                    </Card>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col sm={12} className={`content active-content`}>
                    <Card
                      className="card-stats mb-xl-0"
                      style={{
                        borderRadius: "0.5rem"
                      }}
                    >
                      <CardHeader
                        style={{
                          borderRadius: "0.5rem"
                        }}
                      >
                        <Row className="justify-content-start">
                          <div className="assessment_analysis_card_title ml-2">
                            Assessment Summary
                          </div>
                        </Row>
                      </CardHeader>

                      <Fragment>
                        <CardBody>
                          <Row>
                            <Col lg={6} md={12} className="mt-2">
                              <Table className="table-borderless table_font" responsive>
                                <tr>
                                  <td className="assessment_analysis_summary_text recomm_summary_section">
                                    Assessment Date
                                  </td>
                                  <td className="recomm_summary_separator">:</td>
                                  <td className="recomm_summary_section recomm_summary_section_value">
                                    <b>
                                      {data?.created_at &&
                                        moment(`${data?.created_at}`).format("MM-DD-YYYY")}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="assessment_analysis_summary_text recomm_summary_section">
                                    Username
                                  </td>
                                  <td className="recomm_summary_separator">:</td>
                                  <td className="recomm_summary_section recomm_summary_section_value">
                                    <b>{data?.username}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="assessment_analysis_summary_text recomm_summary_section">
                                    Total Score
                                  </td>
                                  <td className="recomm_summary_separator">:</td>
                                  <td className="recomm_summary_section recomm_summary_section_value">
                                    <b>{data?.total_score}</b>
                                  </td>
                                </tr>
                              </Table>
                            </Col>
                            <Col lg={6} md={12} className="mt-2">
                              <Table className="table-borderless table_font" responsive>
                                <tr>
                                  <td className="assessment_analysis_summary_text recomm_summary_section">
                                    Company Name
                                  </td>
                                  <td className="recomm_summary_separator">:</td>
                                  <td className="recomm_summary_section recomm_summary_section_value">
                                    <b>{data?.company}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="assessment_analysis_summary_text recomm_summary_section">
                                    Assessment Number
                                  </td>
                                  <td className="recomm_summary_separator">:</td>
                                  <td className="recomm_summary_section recomm_summary_section_value">
                                    <b>{data?.attempt_number}</b>
                                  </td>
                                </tr>
                              </Table>
                            </Col>
                          </Row>
                        </CardBody>
                      </Fragment>
                    </Card>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col sm={12} className={`content active-content`}>
                    <Card
                      className="card-stats mb-xl-0"
                      style={{
                        borderRadius: "0.5rem"
                      }}
                    >
                      <CardHeader
                        style={{
                          borderRadius: "0.5rem"
                        }}
                      >
                        <Row className="justify-content-between">
                          <div className="assessment_analysis_card_title ml-2">
                            Recommendation Summary
                          </div>
                        </Row>
                      </CardHeader>

                      <Fragment>
                        <CardBody>
                          <Row>
                            <Col lg={6} md={12} className="mt-2">
                              <Table className="table-borderless table_font" responsive>
                                {data?.table_data?.length
                                  ? data?.table_data
                                      ?.filter((section, index) => index < 7)
                                      ?.map((item, index) => {
                                        const answerValue =
                                          +item?.value && item?.is_financial
                                            ? new Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                                maximumFractionDigits: 0
                                              }).format(Math.abs(Number(item?.value)))
                                            : item?.answer;

                                        return (
                                          <tr key={index + item?.name}>
                                            <td className="assessment_analysis_summary_text recomm_summary_section">
                                              {item?.name}
                                            </td>
                                            <td className="recomm_summary_separator">:</td>
                                            <td className="recomm_summary_section recomm_summary_section_value">
                                              <b
                                                className={
                                                  item?.name === "Company Risk"
                                                    ? `${getCompanyRiskBadgeClass(item?.value)}`
                                                    : ""
                                                }
                                              >
                                                {Array.isArray(item?.value)
                                                  ? item.value.map((answer) => (
                                                      <div key={answer?.id} className="mb-2">
                                                        {answer?.projectName} -{" "}
                                                        {getFormattedContact(answer?.contactNumber)}
                                                      </div>
                                                    ))
                                                  : +item?.value && !item?.is_financial
                                                  ? new Intl.NumberFormat("en-US").format(
                                                      Math.abs(Number(item?.value))
                                                    )
                                                  : +item?.value
                                                  ? answerValue
                                                  : item?.value}
                                              </b>
                                            </td>
                                          </tr>
                                        );
                                      })
                                  : null}
                              </Table>
                            </Col>
                            <Col lg={6} md={12} className="mt-2">
                              <Table className="table-borderless table_font" responsive>
                                {data?.table_data?.length
                                  ? data?.table_data
                                      ?.filter((section, index) => index > 6)
                                      ?.map((item, index) => {
                                        const answerValue =
                                          +item?.value && item?.is_financial
                                            ? new Intl.NumberFormat("en-US", {
                                                style: "currency",
                                                currency: "USD",
                                                maximumFractionDigits: 0
                                              }).format(Math.abs(Number(item?.value)))
                                            : item?.answer;

                                        return (
                                          <tr key={index + item?.name}>
                                            <td className="assessment_analysis_summary_text recomm_summary_section">
                                              {item?.name}
                                            </td>
                                            <td className="recomm_summary_separator">:</td>
                                            <td className="recomm_summary_section recomm_summary_section_value">
                                              <b
                                                className={
                                                  item?.name === "Company Risk"
                                                    ? `${getCompanyRiskBadgeClass(item?.value)}`
                                                    : ""
                                                }
                                              >
                                                {Array.isArray(item?.value)
                                                  ? item.value.map((answer) => (
                                                      <div key={answer?.id} className="mb-2">
                                                        {answer?.projectName} -{" "}
                                                        {getFormattedContact(answer?.contactNumber)}
                                                      </div>
                                                    ))
                                                  : +item?.value && !item?.is_financial
                                                  ? new Intl.NumberFormat("en-US").format(
                                                      Math.abs(Number(item?.value))
                                                    )
                                                  : +item?.value
                                                  ? answerValue
                                                  : item?.value}
                                              </b>
                                            </td>
                                          </tr>
                                        );
                                      })
                                  : null}
                              </Table>
                            </Col>

                            {data?.table_data?.length === 0 ? (
                              <Col sm={12} className="post_error_loading pt-0">
                                No data found.
                              </Col>
                            ) : null}
                          </Row>
                        </CardBody>
                      </Fragment>
                    </Card>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col sm={12} className={`content active-content`}>
                    <Card
                      className="card-stats mb-xl-0"
                      style={{
                        borderRadius: "0.5rem"
                      }}
                    >
                      <CardHeader
                        style={{
                          borderRadius: "0.5rem"
                        }}
                      >
                        <Row className="justify-content-between">
                          <div className="assessment_analysis_card_title ml-2">
                            Assessment Score obtained
                          </div>
                          <div
                            className="profile_current_plan_alert mx-2 px-2"
                            style={{
                              width: "inherit"
                            }}
                          >
                            <Icon
                              icon="eva:pie-chart-outline"
                              width="16"
                              height="16"
                              className="mr-2"
                            />
                            6 Step
                          </div>
                        </Row>
                      </CardHeader>

                      <Fragment>
                        <CardBody>
                          <Row>
                            <Col lg={6} md={12} className="mt-2">
                              <Table className="table-borderless table_font" responsive>
                                {data?.survey_sections?.length
                                  ? data?.survey_sections
                                      ?.filter((section, index) => index < 3)
                                      ?.map((item, index) => {
                                        return (
                                          <tr key={item?.section_id}>
                                            <td className="assessment_analysis_summary_text score_obtained_step_number">
                                              Step {index + 1}
                                            </td>
                                            <td className="score_obtained_separator">:</td>
                                            <td className="recomm_summary_section">
                                              <b>
                                                {assessmentSectionTitles[`${item?.section_id}`]}
                                              </b>
                                            </td>
                                            <td className="score_obtained_step_scored">
                                              <b>
                                                {item?.section_total_score} /{" "}
                                                {item?.section_max_score}
                                              </b>
                                            </td>
                                          </tr>
                                        );
                                      })
                                  : null}
                              </Table>
                            </Col>
                            <Col lg={6} md={12} className="mt-2">
                              <Table className="table-borderless table_font" responsive>
                                {data?.survey_sections?.length
                                  ? data?.survey_sections
                                      ?.filter((section, index) => index > 2)
                                      ?.map((item, index) => {
                                        return (
                                          <tr key={item?.section_id}>
                                            <td className="assessment_analysis_summary_text score_obtained_step_number">
                                              Step {index + 4}
                                            </td>
                                            <td className="score_obtained_separator">:</td>
                                            <td className="recomm_summary_section">
                                              <b>
                                                {assessmentSectionTitles[`${item?.section_id}`]}
                                              </b>
                                            </td>
                                            <td className="score_obtained_step_scored">
                                              <b>
                                                {item?.section_total_score} /{" "}
                                                {item?.section_max_score}
                                              </b>
                                            </td>
                                          </tr>
                                        );
                                      })
                                  : null}
                              </Table>
                            </Col>

                            {data?.survey_sections?.length === 0 ? (
                              <Col sm={12} className="post_error_loading pt-0">
                                No data found.
                              </Col>
                            ) : null}
                          </Row>
                        </CardBody>
                      </Fragment>
                    </Card>
                  </Col>
                </Row>

                <Row className="mt-4 assessment_result_analysis_card">
                  <Col sm={12} className={`content active-content`}>
                    <Card
                      className="card-stats mb-xl-0"
                      style={{
                        borderRadius: "0.5rem"
                      }}
                    >
                      <CardHeader
                        style={{
                          borderRadius: "0.5rem"
                        }}
                      >
                        <Row className="justify-content-start">
                          <div className="assessment_analysis_card_title ml-2">
                            Assessment Result Analysis
                          </div>
                        </Row>
                      </CardHeader>

                      <Fragment>
                        <CardBody>
                          {data?.survey_sections?.map((item, index) => {
                            return (
                              <Row className={index === 0 ? "" : "mt-3"} key={item?.section_id}>
                                <Col
                                  sm={12}
                                  className="d-flex justify-content-between assessment_result_accordian"
                                >
                                  <div>
                                    <b className="mr-2">0{index + 1}</b>
                                    <span className="assessment_result_accordian_title">
                                      {assessmentSectionTitles[`${item?.section_id}`]}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="assessment_result_score">
                                      {item?.normalized_score?.toFixed(2)}
                                    </span>{" "}
                                    <span className="assessment_result_total_score">out of 10</span>
                                  </div>
                                </Col>
                                <Col sm={12} className="mt-2 assessment_result_action_plan_step">
                                  Recommendation:
                                </Col>
                                <Col
                                  sm={12}
                                  className="mt-2 assessment_result_action_plan_description"
                                >
                                  {item?.recommendation}
                                </Col>
                                {item?.action_plan_steps ? (
                                  <>
                                    <Col
                                      sm={12}
                                      className="mt-2 assessment_result_action_plan_step"
                                    >
                                      Action plan steps:
                                    </Col>
                                    <Col
                                      sm={12}
                                      className="mt-2 assessment_result_action_plan_description"
                                    >
                                      {item?.action_plan_steps}
                                    </Col>
                                  </>
                                ) : null}
                              </Row>
                            );
                          })}

                          {data?.survey_sections?.length === 0 ? (
                            <div className="post_error_loading pt-2">No data found.</div>
                          ) : null}
                        </CardBody>
                      </Fragment>
                    </Card>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col sm={12} className="my-3 mx-0 d-flex justify-content-center">
                    {data?.role === userRoles.ADMIN ? null : (
                      <Button
                        style={{ color: "#fff", backgroundColor: "#FF9F43", border: "none" }}
                        className="px-4 py-2 mr-2 assessment_analysis_buttons"
                        onClick={senMailPdfDocument}
                      >
                        Send in Email
                      </Button>
                    )}

                    <Button
                      color="primary"
                      className=" px-5 py-2 mr-2 assessment_analysis_buttons"
                      type="submit"
                      onClick={downloadPdfDocument}
                    >
                      Export
                    </Button>
                  </Col>
                </Row>
              </Fragment>
            )}
          </>
        )}
      </div>

      <div
        className="px-3"
        ref={ref}
        style={{
          display: "none",
          width: "1078px"
        }}
      >
        <Row className="justify-content-between align-items-center mx-1">
          <span className="Headers">{/* <h4>View analysis</h4> */}</span>
        </Row>

        {isLoading ? (
          <Fragment>
            <div className="post_error_loading">
              <Loader />
            </div>
          </Fragment>
        ) : (
          <>
            {error ? (
              <Fragment>
                <div className="post_error_loading">{error}</div>
              </Fragment>
            ) : (
              <Fragment>
                <Row className="mt-2">
                  <Col sm={12} className={`content active-content`}>
                    <Card
                      className="card-stats mb-xl-0"
                      style={{
                        borderRadius: "0.5rem"
                      }}
                    >
                      <CardHeader
                        style={{
                          borderRadius: "0.5rem"
                        }}
                      >
                        <Row className="justify-content-between">
                          <div className="assessment_analysis_card_title ml-2">
                            Assessment Summary
                          </div>
                        </Row>
                      </CardHeader>

                      <Fragment>
                        <CardBody>
                          <Row>
                            <Col xs={12} className="mt-2">
                              <Table className="table-borderless table_font" responsive>
                                <tr>
                                  <td className="assessment_analysis_summary_text_pdf">Name</td>
                                  <td className="summary_saperator">:</td>
                                  <td>
                                    <b>
                                      {pdfData?.user?.first_name}
                                      {pdfData?.user?.last_name}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="assessment_analysis_summary_text_pdf">UserName</td>
                                  <td className="summary_saperator">:</td>
                                  <td>
                                    <b>{pdfData?.user?.username}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="assessment_analysis_summary_text_pdf">Email</td>
                                  <td className="summary_saperator">:</td>
                                  <td>
                                    <b>{pdfData?.user?.email}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="assessment_analysis_summary_text_pdf">Company</td>
                                  <td className="summary_saperator">:</td>
                                  <td>
                                    <b>{pdfData?.user?.company ? pdfData?.user?.company : "NA"}</b>
                                  </td>
                                </tr>

                                <tr>
                                  <td className="assessment_analysis_summary_text_pdf">
                                    Job Title
                                  </td>
                                  <td className="summary_saperator">:</td>
                                  <td>
                                    <b>
                                      {pdfData?.user?.job_title ? pdfData?.user?.job_title : "NA"}
                                    </b>
                                  </td>
                                </tr>

                                <tr>
                                  <td className="assessment_analysis_summary_text_pdf">
                                    Industrie
                                  </td>
                                  <td className="summary_saperator">:</td>
                                  <td>
                                    <b>
                                      {pdfData?.user?.industries ? pdfData?.user?.industries : "NA"}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="assessment_analysis_summary_text_pdf">
                                    Naics code
                                  </td>
                                  <td className="summary_saperator">:</td>
                                  <td>
                                    <b>
                                      {pdfData?.user?.naics_code ? pdfData?.user?.naics_code : "NA"}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="assessment_analysis_summary_text_pdf">
                                    Certification Type
                                  </td>
                                  <td className="summary_saperator">:</td>
                                  <td>
                                    <b>
                                      {pdfData?.user?.certification_type
                                        ? pdfData?.user?.certification_type
                                        : "NA"}
                                    </b>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="assessment_analysis_summary_text_pdf">
                                    Phone Number
                                  </td>
                                  <td className="summary_saperator">:</td>
                                  <td>
                                    <b>{pdfData?.user?.phone}</b>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="assessment_analysis_summary_text_pdf">
                                    Year Founded
                                  </td>
                                  <td className="summary_saperator">:</td>
                                  <td>
                                    <b>
                                      {pdfData?.user?.year_founded
                                        ? pdfData?.user?.year_founded
                                        : "NA"}
                                    </b>
                                  </td>
                                </tr>
                              </Table>
                            </Col>

                            {data?.table_data?.length === 0 ? (
                              <Col sm={12} className="post_error_loading pt-0">
                                No data found.
                              </Col>
                            ) : null}
                          </Row>
                        </CardBody>
                      </Fragment>
                    </Card>
                  </Col>
                </Row>

                <Row className="mt-4">
                  <Col sm={12} className={`content active-content`}>
                    <Card
                      className="card-stats mb-xl-0"
                      style={{
                        borderRadius: "0.5rem"
                      }}
                    >
                      <CardHeader
                        style={{
                          borderRadius: "0.5rem"
                        }}
                      >
                        <Row className="justify-content-between">
                          <div className="assessment_analysis_card_title ml-2">
                            Recommendation Summary
                          </div>
                        </Row>
                      </CardHeader>

                      <Fragment>
                        <CardBody>
                          <Row>
                            <Col xs={12} className="mt-2">
                              <Table className="table-borderless table_font" responsive>
                                {data?.table_data?.length
                                  ? data?.table_data?.map((item, index) => {
                                      const answerValue =
                                        +item?.value && item?.is_financial
                                          ? new Intl.NumberFormat("en-US", {
                                              style: "currency",
                                              currency: "USD",
                                              maximumFractionDigits: 0
                                            }).format(Math.abs(Number(item?.value)))
                                          : item?.answer;

                                      return (
                                        <tr key={index + item?.name}>
                                          <td className="assessment_analysis_summary_text_pdf">
                                            {item?.name}
                                          </td>
                                          <td className="summary_saperator">:</td>
                                          <td>
                                            <b
                                              className={
                                                item?.name === "Company Risk"
                                                  ? getCompanyRiskBadgeClass(item?.value)
                                                  : ""
                                              }
                                            >
                                              {Array.isArray(item?.value)
                                                ? item.value.map((answer) => (
                                                    <div key={answer?.id} className="mb-2">
                                                      {answer?.projectName} -{" "}
                                                      {getFormattedContact(answer?.contactNumber)}
                                                    </div>
                                                  ))
                                                : +item?.value && !item?.is_financial
                                                ? new Intl.NumberFormat("en-US").format(
                                                    Math.abs(Number(item?.value))
                                                  )
                                                : +item?.value
                                                ? answerValue
                                                : item?.value}
                                            </b>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  : null}
                              </Table>
                            </Col>

                            {data?.table_data?.length === 0 ? (
                              <Col sm={12} className="post_error_loading pt-0">
                                No data found.
                              </Col>
                            ) : null}
                          </Row>
                        </CardBody>
                      </Fragment>
                    </Card>
                  </Col>
                </Row>
                <Row className="mt-5"></Row>
                <Row className="mt-5"></Row>
                <Row className="mt-3 assessment_result_analysis_card">
                  <Col xs={12} className={`content active-content`}>
                    <Card
                      className="card-stats mb-xl-0"
                      style={{
                        borderRadius: "0.5rem",
                        marginTop: "11px"
                      }}
                    >
                      <Fragment>
                        <CardBody>
                          <Row className={"mt-3 survey-section-row"}>
                            <PDFQuestionTable
                              questionList={questionList?.filter((item, ind) => ind < 13)}
                            />

                            <Col xs={12} className="mb-5"></Col>
                            <Col xs={12} className="mb-2"></Col>
                            <PDFQuestionTable
                              questionList={questionList?.filter(
                                (item, ind) => ind > 12 && ind < 28
                              )}
                            />
                            <Col xs={12} className="mb-5"></Col>
                            <Col xs={12} className="mb-1"></Col>
                            <PDFQuestionTable
                              questionList={questionList?.filter(
                                (item, ind) => ind > 27 && ind < 43
                              )}
                            />
                            <Col xs={12} className="mb-3"></Col>
                            <PDFQuestionTable
                              questionList={questionList?.filter(
                                (item, ind) => ind > 42 && ind < 58
                              )}
                            />
                            <Col xs={12} className="mb-5"></Col>
                            <PDFQuestionTable
                              questionList={questionList?.filter(
                                (item, ind) => ind > 57 && ind < 73
                              )}
                            />
                            <Col xs={12} className="mb-5"></Col>
                            <Col xs={12} className="mb-2"></Col>
                            <PDFQuestionTable
                              questionList={questionList?.filter((item, ind) => ind > 72)}
                            />
                          </Row>

                          {pdfData?.survey_sections?.length === 0 ? (
                            <div className="post_error_loading pt-2">No data found.</div>
                          ) : null}
                        </CardBody>
                      </Fragment>
                    </Card>
                  </Col>
                </Row>

                <Row
                  style={{
                    marginBottom: "845px"
                  }}
                ></Row>

                <Row className="">
                  <Col xs={12} className={`content active-content`}>
                    <Card
                      className="card-stats mb-0"
                      style={{
                        borderRadius: "0.5rem"
                      }}
                    >
                      <CardHeader
                        style={{
                          borderRadius: "0.5rem"
                        }}
                      >
                        <Row className="d-flex justify-content-between flex-nowrap">
                          <div className="assessment_analysis_card_title ml-2">
                            Assessment analysis
                          </div>
                          <div
                            className="profile_current_plan_alert mx-2 px-2"
                            style={{
                              width: "inherit"
                            }}
                          >
                            <Icon
                              icon="eva:pie-chart-outline"
                              width="16"
                              height="16"
                              className="mr-2"
                            />
                            The score is out of 10
                          </div>
                        </Row>
                      </CardHeader>

                      <Fragment>
                        <CardBody>
                          <Row className="align-items-center">
                            <Col xs={4} className="mt-2">
                              <Table className="table-borderless table_font" responsive>
                                {data?.survey_sections?.length
                                  ? data?.survey_sections?.map((item) => {
                                      return (
                                        <tr key={item?.section_id}>
                                          <td className="assessment_analysis_text">
                                            {assessmentSectionTitles[`${item?.section_id}`]}
                                          </td>
                                          <td>:</td>
                                          <td>
                                            <b>{item?.normalized_score?.toFixed(2)}</b>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  : null}
                              </Table>
                            </Col>
                            <Col xs={8} className="mt-2">
                              {graphData?.length ? (
                                <HeatMapChart
                                  data={graphData}
                                  categories={graphTitles}
                                  width={false}
                                />
                              ) : null}
                            </Col>

                            {data?.survey_sections?.length === 0 ? (
                              <Col sm={12} className="post_error_loading pt-0">
                                No data found.
                              </Col>
                            ) : null}
                          </Row>
                        </CardBody>
                      </Fragment>
                    </Card>
                  </Col>
                </Row>

                <Row className="mt-2">
                  <Col xs={12} className={`content active-content`}>
                    <Card
                      className="card-stats mb-0"
                      style={{
                        borderRadius: "0.5rem"
                      }}
                    >
                      <CardHeader
                        style={{
                          borderRadius: "0.5rem"
                        }}
                      >
                        <Row className="justify-content-between">
                          <div className="assessment_analysis_card_title ml-2">
                            Assessment Score obtained
                          </div>
                          <div
                            className="profile_current_plan_alert mx-2 px-2"
                            style={{
                              width: "inherit"
                            }}
                          >
                            <Icon
                              icon="eva:pie-chart-outline"
                              width="16"
                              height="16"
                              className="mr-2"
                            />
                            6 Step
                          </div>
                        </Row>
                      </CardHeader>

                      <Fragment>
                        <CardBody>
                          <Row>
                            <Col xs={6} className="mt-2">
                              <Table className="table-borderless table_font" responsive>
                                {data?.survey_sections?.length
                                  ? data?.survey_sections
                                      ?.filter((section, index) => index < 3)
                                      ?.map((item, index) => {
                                        return (
                                          <tr key={item?.section_id}>
                                            <td className="assessment_analysis_summary_text">
                                              Step {index + 1}
                                            </td>
                                            <td>:</td>
                                            <td>
                                              <b>
                                                {assessmentSectionTitles[`${item?.section_id}`]}
                                              </b>
                                            </td>
                                            <td>
                                              <b>
                                                {item?.section_total_score} /{" "}
                                                {item?.section_max_score}
                                              </b>
                                            </td>
                                          </tr>
                                        );
                                      })
                                  : null}
                              </Table>
                            </Col>
                            <Col xs={6} className="mt-2">
                              <Table className="table-borderless table_font" responsive>
                                {data?.survey_sections?.length
                                  ? data?.survey_sections
                                      ?.filter((section, index) => index > 2)
                                      ?.map((item, index) => {
                                        return (
                                          <tr key={item?.section_id}>
                                            <td className="assessment_analysis_summary_text">
                                              Step {index + 4}
                                            </td>
                                            <td>:</td>
                                            <td>
                                              <b>
                                                {assessmentSectionTitles[`${item?.section_id}`]}
                                              </b>
                                            </td>
                                            <td>
                                              <b>
                                                {item?.section_total_score} /{" "}
                                                {item?.section_max_score}
                                              </b>
                                            </td>
                                          </tr>
                                        );
                                      })
                                  : null}
                              </Table>
                            </Col>

                            {data?.survey_sections?.length === 0 ? (
                              <Col xs={12} className="post_error_loading pt-0">
                                No data found.
                              </Col>
                            ) : null}
                          </Row>
                        </CardBody>
                      </Fragment>
                    </Card>
                  </Col>
                </Row>

                <Row
                  style={{
                    marginBottom: "390px"
                  }}
                ></Row>
                <Row className="mt-2 assessment_result_analysis_card">
                  <Col xs={12} className={`content active-content`}>
                    <Card
                      className="card-stats mb-0"
                      style={{
                        borderRadius: "0.5rem"
                      }}
                    >
                      <CardHeader
                        style={{
                          borderRadius: "0.5rem"
                        }}
                      >
                        <Row className="justify-content-start">
                          <div className="assessment_analysis_card_title ml-2">
                            Assessment Result Analysis
                          </div>
                        </Row>
                      </CardHeader>

                      <Fragment>
                        <CardBody className="mb-0 pb-0">
                          {data?.survey_sections?.map((item, index) => {
                            return (
                              <Row
                                className={
                                  index === 0
                                    ? "mt-n1 survey-section-row"
                                    : "mt-2 survey-section-row"
                                }
                                key={item?.section_id}
                              >
                                <Col
                                  xs={12}
                                  className="d-flex justify-content-between assessment_result_accordian"
                                >
                                  <div>
                                    <b className="mr-2">0{index + 1}</b>
                                    <span className="assessment_result_accordian_title font-weight-bold">
                                      {assessmentSectionTitles[`${item?.section_id}`]}
                                    </span>
                                  </div>
                                  <div>
                                    <span className="font-weight-bold p-1 text-dark">
                                      {item?.normalized_score?.toFixed(2)}
                                    </span>{" "}
                                    <span className="assessment_result_total_score">out of 10</span>
                                  </div>
                                </Col>
                                <Col
                                  sm={12}
                                  className="assessment_result_action_plan_step font-weight-bold"
                                >
                                  Recommendation:
                                </Col>
                                <Col
                                  sm={12}
                                  className="mt-2 assessment_result_action_plan_description"
                                >
                                  {item?.recommendation}
                                </Col>
                                {item?.action_plan_steps ? (
                                  <>
                                    <Col
                                      sm={12}
                                      className="mt-2 assessment_result_action_plan_step font-weight-bold"
                                    >
                                      Action plan steps:
                                    </Col>
                                    <Col
                                      sm={12}
                                      className="mt-2 assessment_result_action_plan_description"
                                    >
                                      {item?.action_plan_steps}
                                    </Col>
                                  </>
                                ) : null}
                              </Row>
                            );
                          })}

                          {data?.survey_sections?.length === 0 ? (
                            <div className="post_error_loading pt-2">No data found.</div>
                          ) : null}
                        </CardBody>
                      </Fragment>
                    </Card>
                  </Col>
                </Row>
              </Fragment>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default AssessmentAnalysis;
