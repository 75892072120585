import { useState, useEffect } from "react";
import { Button, Col, Form, FormGroup, Input, Progress, Row } from "reactstrap";
import startDateIcon from "assets/img/brand/startdate_icon.png";
import companyNameIcon from "assets/img/brand/company_name_icon.png";
import surveyNumberIcon from "assets/img/brand/survey_number_icon.png";
import { styled } from "@mui/material/styles";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import "./GovernmentAddSurvey.css";
import StartSurveyModal from "components/Modals/StartSurveyModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchAssessmentQuestionListThunkAction } from "redux/firmsListofAssessment/actions";
import { selectAssessmentList } from "redux/firmsListofAssessment/selectors";
import Loader from "components/Loader/Loader";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import userRoles from "utils/roles";
import { selectUserRole } from "redux/user/selectors";
import { submitAssessmentThunkAction } from "redux/firmsListofAssessment/actions";
import moment from "moment";
import toast from "react-hot-toast";
import { fetchPendingAssessmentQuestionListThunkAction } from "redux/firmsListofAssessment/actions";
import { assessmentSectionTitles } from "utils/roles";
import Swal from "sweetalert2";
import NumberFormat from "react-number-format";
import { capitalize } from "utils/roles";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  margin: "1rem 0",
  widows: "100%",
  borderRadius: "0.5rem",
  "&:not(:last-child)": {},
  "&:before": {
    display: "none"
  }
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary expandIcon={<ArrowCircleRightOutlinedIcon />} {...props} />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)"
  },
  "& .MuiAccordionSummary-content": {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)"
}));

function GovernmentAddSurvey() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { id: assessmentId } = useParams();

  const role = useSelector(selectUserRole) || localStorage.getItem("role");
  const { isLoading, assessmentQuestionSectionsList, startSurveyData } =
    useSelector(selectAssessmentList);
  const isPendingPage = pathname.includes("pending");
  const isNewSurveyPage = pathname.includes("add");

  const [expanded, setExpanded] = useState(1);
  const [sections, setSections] = useState([]);
  const [sectionsCount, setSectionsCount] = useState([]);
  const [open, setOpen] = useState(true);
  const [disableAutoFill, setDisableAutoFill] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const toggleModal = () => {
    setOpen((open) => false);
  };

  const onSuccess = () => {
    setError(null);
  };

  const onError = (error) => {
    setError(error);
  };

  const NavigateOnSave = () => {
    switch (Number(role)) {
      case userRoles.ADMIN:
        return "/admin/start/Assessment";
      case userRoles.GOVT:
        return "/gov/Assessment/list";
      case userRoles.FIRMS:
        return "/firms/Assessment/list";
      case userRoles.GOVT_FIRMS:
        return "/govt-firms/Assessment/list";
      default:
        return "/Assessment/list";
    }
  };

  const NavigateOnSubmit = (id) => {
    switch (Number(role)) {
      case userRoles.ADMIN:
        return `/admin/assessment/analysis/${id}`;
      case userRoles.GOVT:
        return `/gov/assessment/analysis/${id}`;
      case userRoles.FIRMS:
        return `/firms/assessment/analysis/${id}`;
      case userRoles.GOVT_FIRMS:
        return `/govt-firms/assessment/analysis/${id}`;
      default:
        return `/assessment/analysis/${id}`;
    }
  };

  useEffect(() => {
    if (isNewSurveyPage) {
      dispatch(fetchAssessmentQuestionListThunkAction(onSuccess, onError));
    }
    window.onload = function () {
      navigate(`${NavigateOnSave()}`, {
        replace: true
      });
    };
  }, []);

  useEffect(() => {
    if (assessmentQuestionSectionsList?.sectionList?.length) {
      setSections(assessmentQuestionSectionsList?.sectionList);
    }
  }, [assessmentQuestionSectionsList?.sectionList]);

  useEffect(() => {
    if (assessmentQuestionSectionsList?.sectionAttendedQuestions) {
      setSectionsCount(assessmentQuestionSectionsList?.sectionAttendedQuestions);
    }
  }, [assessmentQuestionSectionsList?.sectionAttendedQuestions]);

  const onClickRandomFillAssessment = () => {
    const randomSections = sections.map((section) => {
      return {
        ...section,
        questions: section.questions.map((question) => {
          if (question.type === "number") {
            return {
              ...question,
              answer: question.answer
                ? question.answer
                : Math.floor(Math.random() * 1000).toString()
            };
          }
          if (question.type === "radio_button") {
            return {
              ...question,
              answer: question.answer
                ? question.answer
                : (Math.floor(Math.random() * question.options.length) + 1).toString()
            };
          }
          if (question.type === "check_box") {
            return {
              ...question,
              answer:
                question.answer.length > 0
                  ? question.answer
                  : question.options
                      .map((_, index) => {
                        return (index + 1).toString();
                      })
                      .filter(() => Math.random() > 0.5)
            };
          }
          if (question.type === "text") {
            return {
              ...question,
              answer: question.answer ? question.answer : "RandomText"
            };
          }
          if (question.type === "3-text") {
            return {
              ...question,
              answer: question.answer.map((ans) => {
                return {
                  ...ans,
                  projectName: ans.projectName ? ans.projectName : "RandomProject",
                  contactNumber: ans.contactNumber
                    ? ans.contactNumber
                    : Math.floor(1000000000 + Math.random() * 9000000000).toString()
                };
              })
            };
          }
        })
      };
    });

    setSections(randomSections);
    setDisableAutoFill(true);
  };

  const answerHandler = (e, question, section_id) => {
    setSections((preData) =>
      preData.map((preSection) => {
        if (preSection?.section_id === section_id) {
          return {
            ...preSection,

            questions: preSection.questions.map((preQuestion) => {
              if (preQuestion?.question_id === question?.question_id) {
                if (preQuestion?.answer === e.target.value) {
                  return {
                    ...preQuestion,
                    answer: ""
                  };
                }

                return {
                  ...preQuestion,
                  answer: e.target.value.replaceAll(",", "").replace("$", "")
                };
              }
              return preQuestion;
            })
          };
        }
        return preSection;
      })
    );
  };

  const threeAnswerHandler = (e, question, section_id, answerId, answerField) => {
    setSections((preData) =>
      preData.map((preSection) => {
        if (preSection?.section_id === section_id) {
          return {
            ...preSection,

            questions: preSection.questions.map((preQuestion) => {
              if (preQuestion?.question_id === question?.question_id) {
                return {
                  ...preQuestion,
                  answer: preQuestion.answer.map((answer) => {
                    if (answer.id === answerId) {
                      return {
                        ...answer,
                        [`${answerField}`]: e.target.value
                      };
                    }
                    return answer;
                  })
                };
              }
              return preQuestion;
            })
          };
        }
        return preSection;
      })
    );
  };

  useEffect(() => {
    if (sections.length > 0) {
      setSectionsCount((preData) =>
        preData.map((section) => {
          const temp = sections
            ?.find((sec) => sec?.section_id === section?.sectionId)
            ?.questions?.filter((item) => {
              if (Array.isArray(item.answer)) {
                if (item?.type === "3-text") {
                  return item.answer.every((item) => {
                    return (
                      item?.projectName?.trim() &&
                      item?.contactNumber
                        ?.trim()
                        ?.replace("-", "")
                        ?.replace("(", "")
                        ?.replace(")", "")
                        ?.replace(" ", "")
                        ?.replace("_", "").length === 10
                    );
                  });
                }

                if (item.answer.length > 0) {
                  return true;
                }
                return false;
              }
              return item.answer ? true : false;
            }).length;
          return {
            ...section,
            attendedQuestions: temp
          };
        })
      );
    }
  }, [sections]);

  useEffect(() => {
    if (assessmentId) {
      dispatch(fetchPendingAssessmentQuestionListThunkAction(assessmentId));
    }
  }, [assessmentId]);

  const checkboxAnswerHandler = (e, question, section_id) => {
    setSections((preData) =>
      preData.map((preSection) => {
        if (preSection?.section_id === section_id) {
          return {
            ...preSection,
            questions: preSection.questions.map((preQuestion) => {
              if (preQuestion?.question_id === question?.question_id) {
                if (preQuestion?.answer?.find((item) => item === e.target.value)) {
                  return {
                    ...preQuestion,
                    answer: preQuestion?.answer?.filter((item) => item !== e.target.value)
                  };
                }

                return {
                  ...preQuestion,
                  answer: [...preQuestion?.answer, e.target.value]
                };
              }
              return preQuestion;
            })
          };
        }
        return preSection;
      })
    );
  };

  const totalAttendedQuestions = sectionsCount?.reduce((acc, curr) => {
    return acc + curr?.attendedQuestions;
  }, 0);

  const getReqBodyData = () => {
    return sections.map((item) => {
      const checkProgressCount = sectionsCount?.find(
        (sect) => sect?.sectionId === item?.section_id
      )?.attendedQuestions;

      return {
        ...item,
        title: undefined,
        total_section_question: undefined,
        section_total_score: undefined,
        completion_percent: undefined,
        serial_number: undefined,
        normalized_score: undefined,
        section_completion_percent: (
          (checkProgressCount * 100) /
          item?.total_section_question
        )?.toFixed(2),
        questions: item?.questions?.map((ques) => {
          if (ques?.type === "number") {
            return {
              ...ques,
              answer_index: ques?.answer ? ques?.answer : undefined,
              answer_value: ques?.answer && !ques?.is_financial ? ques?.answer : undefined,
              answer: undefined,
              score: undefined,
              question: undefined,
              serial_number: undefined
            };
          }

          if (ques?.type === "check_box") {
            return {
              ...ques,
              answer_index: ques?.answer?.length ? ques?.answer.join(",") : undefined,
              answer: undefined,
              score: undefined,
              serial_number: undefined,
              question: undefined
            };
          }
          if (ques?.type === "text") {
            return {
              ...ques,
              answer_index: ques?.answer ? capitalize(ques?.answer) : undefined,
              answer_value: ques?.answer ? capitalize(ques?.answer) : undefined,
              answer: undefined,
              score: undefined,
              serial_number: undefined,
              question: undefined
            };
          }
          if (ques?.type === "3-text") {
            const answer = JSON.stringify(
              ques?.answer?.map((ans) => {
                if (ans?.contactNumber) {
                  return {
                    ...ans,
                    contactNumber: ans?.contactNumber
                      .replace("-", "")
                      .replace("(", "")
                      .replace(")", "")
                      .replace(" ", "")
                  };
                }
                return ans;
              })
            );

            return {
              ...ques,
              answer_value: ques?.answer ? answer : undefined,
              answer_index: "3-text",
              answer: undefined,
              score: undefined,
              serial_number: undefined,
              question: undefined
            };
          }
          return {
            ...ques,
            answer_index: ques?.answer ? ques?.answer : undefined,
            answer: undefined,
            score: undefined,
            serial_number: undefined,
            question: undefined
          };
        })
      };
    });
  };

  const onSubmitHandler = (e) => {
    e.preventDefault();

    const hasSurveyCompleted = sectionsCount.every(
      (section) => section.totalQuestions === section.attendedQuestions
    );

    if (!hasSurveyCompleted) {
      Swal.fire({
        position: "center",
        icon: "warning",
        html: `<h5>Your survey is incomplete. To submit the survey please attempt all the questions. You can save the current survey and resume it later.</h5>`,
        showConfirmButton: false,
        showCancelButton: true
      });
      return;
    }
    const totalPercentage = (
      (totalAttendedQuestions * 100) /
      assessmentQuestionSectionsList?.totalSurveyQuestions
    )?.toFixed(2);

    const reqBody = {
      survey_report_id: Number(startSurveyData?.id),
      total_completion_percent: Number(totalPercentage),
      is_finished: true,
      sections: getReqBodyData()
    };

    dispatch(
      submitAssessmentThunkAction(
        reqBody,
        () => {
          navigate(`${NavigateOnSubmit(Number(startSurveyData?.id))}`, {
            replace: true
          });
        },
        (error) => {}
      )
    );
  };

  const onSaveHandler = () => {
    const totalPercentage = (
      (totalAttendedQuestions * 100) /
      assessmentQuestionSectionsList?.totalSurveyQuestions
    )?.toFixed(2);

    const reqBody = {
      survey_report_id: Number(startSurveyData?.id),
      total_completion_percent: Number(totalPercentage),
      is_finished: false,
      sections: getReqBodyData()
    };

    dispatch(
      submitAssessmentThunkAction(
        reqBody,
        () => {
          navigate(`${NavigateOnSave()}`, {
            replace: true
          });
        },
        (error) => {}
      )
    );
  };

  const questionTypeNumberAnswer = (question, section) => {
    const answer = sections
      ?.find((sec) => sec?.section_id === section?.section_id)
      ?.questions?.find((ques) => ques?.question_id === question?.question_id)?.answer;

    if (!answer) {
      return "";
    }
    return (question?.is_financial ? "$" : "") + new Intl.NumberFormat("en-US").format(answer);
  };
  return (
    <>
      <div className="px-3">
        <div className="d-flex justify-content-between my-2">
          <h4>Start Assessment</h4>
          {Number(role) === userRoles.ADMIN ? (
            <Button
              style={{ color: "white", backgroundColor: "#FF9F43", border: "none" }}
              disabled={disableAutoFill}
              className="px-5 py-2"
              onClick={onClickRandomFillAssessment}
            >
              Auto Fill
            </Button>
          ) : null}
        </div>

        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center w-100">
            <Loader />
          </div>
        ) : (
          <>
            <Row className="mt-4 justify-content-between">
              <Col
                lg={6}
                className="d-flex flex-wrap justify-content-between"
                style={{
                  width: "100%"
                }}
              >
                {Number(role) === userRoles.ADMIN ? null : (
                  <div className="d-flex">
                    <img src={startDateIcon} alt="startDate icon" className="mr-3" />
                    <div className="add_survey_startdate_label">
                      Start Date
                      <br />
                      <span className="add_survey_startdate_value">
                        {moment(`${startSurveyData?.start_time}`).format("MM-DD-YYYY")}
                      </span>
                    </div>
                  </div>
                )}

                <div className="d-flex">
                  <img src={companyNameIcon} alt="startDate icon" className="mr-3" />
                  <div className="add_survey_startdate_label">
                    Company Name
                    <br />
                    <span className="add_survey_startdate_value">{startSurveyData?.company}</span>
                  </div>
                </div>
                {Number(role) === userRoles.ADMIN ? null : (
                  <div className="d-flex">
                    <img src={surveyNumberIcon} alt="startDate icon" className="mr-3" />
                    <div className="add_survey_startdate_label">
                      Assessment No
                      <br />
                      <span className="add_survey_startdate_value">
                        {startSurveyData?.attempt_number}
                      </span>
                    </div>
                  </div>
                )}
              </Col>
              <Col lg={6} xl={4}>
                <div className="add_survey_startdate_label">Assessment Progress</div>
                <Progress
                  color="progressbar"
                  style={{
                    height: "1.2rem",
                    borderRadius: "10px"
                  }}
                  value={totalAttendedQuestions}
                  max={assessmentQuestionSectionsList?.totalSurveyQuestions}
                >
                  {(
                    (totalAttendedQuestions * 100) /
                    assessmentQuestionSectionsList?.totalSurveyQuestions
                  ).toFixed(2)}
                  %
                </Progress>
              </Col>
            </Row>

            <Form role="form" onSubmit={onSubmitHandler}>
              <Row className="mt-4">
                <Col>
                  {sections.map((section, index) => {
                    const checkProgressCount = sectionsCount?.find(
                      (item) => item.sectionId === section.section_id
                    )?.attendedQuestions;
                    return (
                      <Accordion
                        key={section.section_id}
                        expanded={expanded === section.section_id}
                        onChange={handleChange(section.section_id)}
                      >
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                          <Typography>
                            <span
                              className="mr-2 text-capitalize"
                              style={{
                                fontWeight: "500"
                              }}
                            >
                              {assessmentSectionTitles[`${section?.section_id}`]}
                            </span>{" "}
                            <span>({section.questions.length} Questions)</span>
                          </Typography>

                          <Progress
                            style={{
                              borderRadius: "10px",
                              width: "40%",
                              minWidth: "2rem",
                              marginRight: "0",
                              textAlign: "right"
                            }}
                            value={checkProgressCount}
                            max={section?.total_section_question}
                          >
                            {((checkProgressCount * 100) / section?.total_section_question).toFixed(
                              2
                            )}
                            %
                          </Progress>
                        </AccordionSummary>
                        <AccordionDetails>
                          {section.questions.map((question, index) => {
                            return (
                              <Row key={question.question_id}>
                                <Col
                                  xs={12}
                                  className="mb-2"
                                  style={{
                                    fontWeight: "500"
                                  }}
                                >
                                  <b className="mr-2">{question.serial_number}</b>{" "}
                                  {question.question}
                                </Col>
                                {question.type === "number" ? (
                                  <>
                                    <Col sm={6}>
                                      <FormGroup>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Answer"
                                          id="userName"
                                          name="userName"
                                          step={question?.is_financial ? 0.00001 : 0}
                                          onWheel={(e) => e.target.blur()}
                                          min={0}
                                          onChange={(e) => {
                                            if (
                                              Number(
                                                e.target.value.replaceAll(",", "").replace("$", "")
                                              ) > -1
                                            ) {
                                              answerHandler(e, question, section?.section_id);
                                            }
                                          }}
                                          value={questionTypeNumberAnswer(question, section)}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </>
                                ) : null}
                                {question.type === "radio_button" ? (
                                  <>
                                    <Col>
                                      <FormGroup>
                                        <Row>
                                          {question?.options?.map((option, index) => {
                                            return (
                                              <Col
                                                key={`${
                                                  section?.section_id +
                                                  question.question_id +
                                                  option +
                                                  index
                                                }`}
                                                sm={6}
                                                md={4}
                                              >
                                                <input
                                                  type="radio"
                                                  name={`${question.question_id + option + index}`}
                                                  id={`${question.question_id + option + index}`}
                                                  className="mr-2"
                                                  onChange={() => {}}
                                                  onClick={(e) =>
                                                    answerHandler(e, question, section?.section_id)
                                                  }
                                                  value={`${index + 1}`}
                                                  checked={
                                                    sections
                                                      ?.find(
                                                        (sec) =>
                                                          sec?.section_id === section?.section_id
                                                      )
                                                      ?.questions.find(
                                                        (ques) =>
                                                          ques?.question_id ===
                                                          question?.question_id
                                                      ).answer === String(index + 1)
                                                  }
                                                />
                                                <label
                                                  htmlFor={`${
                                                    question.question_id + option + index
                                                  }`}
                                                  className="mr-4"
                                                >
                                                  {option}
                                                </label>
                                              </Col>
                                            );
                                          })}
                                        </Row>
                                      </FormGroup>
                                    </Col>
                                  </>
                                ) : null}

                                {question.type === "check_box" ? (
                                  <>
                                    <Col>
                                      <FormGroup>
                                        <Row>
                                          {question?.options?.map((option, index) => {
                                            return (
                                              <Col
                                                key={`${question.question_id + option + index}`}
                                                sm={6}
                                                md={4}
                                              >
                                                <input
                                                  type="checkbox"
                                                  name={`${question.question_id}`}
                                                  id={`${question.question_id + option + index}`}
                                                  className="mr-2"
                                                  value={`${index + 1}`}
                                                  onChange={() => {}}
                                                  onClick={(e) =>
                                                    checkboxAnswerHandler(
                                                      e,
                                                      question,
                                                      section?.section_id
                                                    )
                                                  }
                                                  checked={sections
                                                    ?.find(
                                                      (sec) =>
                                                        sec?.section_id === section?.section_id
                                                    )
                                                    ?.questions.find(
                                                      (ques) =>
                                                        ques?.question_id === question?.question_id
                                                    )
                                                    .answer?.includes(String(index + 1))}
                                                />
                                                <label
                                                  htmlFor={`${
                                                    question.question_id + option + index
                                                  }`}
                                                  className="mr-4"
                                                >
                                                  {option}
                                                </label>
                                              </Col>
                                            );
                                          })}
                                        </Row>
                                      </FormGroup>
                                    </Col>
                                  </>
                                ) : null}
                                {question?.type === "text" ? (
                                  <>
                                    <Col sm={6}>
                                      <FormGroup>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Answer"
                                          id="userName"
                                          name="userName"
                                          onChange={(e) => {
                                            answerHandler(e, question, section?.section_id);
                                          }}
                                          value={
                                            sections
                                              ?.find(
                                                (sec) => sec?.section_id === section?.section_id
                                              )
                                              ?.questions.find(
                                                (ques) =>
                                                  ques?.question_id === question?.question_id
                                              ).answer
                                          }
                                        />
                                      </FormGroup>
                                    </Col>
                                  </>
                                ) : null}

                                {question?.type === "3-text" ? (
                                  <>
                                    {question?.answer?.map((ans, ind, arr) => {
                                      return (
                                        <>
                                          <Col sm={6}>
                                            <FormGroup>
                                              <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Project Name"
                                                id={ind + "project"}
                                                name={`${ans?.id}`}
                                                onChange={(e) => {
                                                  threeAnswerHandler(
                                                    e,
                                                    question,
                                                    section?.section_id,
                                                    ans?.id,
                                                    "projectName"
                                                  );
                                                }}
                                                value={ans?.projectName}
                                              />
                                            </FormGroup>
                                          </Col>
                                          <Col sm={6}>
                                            <FormGroup>
                                              <NumberFormat
                                                className="form-control"
                                                format="(###) ###-####"
                                                mask="_"
                                                placeholder="Contact Number"
                                                id={ind + "contact"}
                                                name={`${ans?.id}`}
                                                onChange={(e) => {
                                                  threeAnswerHandler(
                                                    e,
                                                    question,
                                                    section?.section_id,
                                                    ans?.id,
                                                    "contactNumber"
                                                  );
                                                }}
                                                value={ans?.contactNumber}
                                              />
                                            </FormGroup>
                                          </Col>
                                          {ind !== arr?.length - 1 ? (
                                            <Col
                                              xs={8}
                                              className="w-full mx-auto mb-3 border px-3"
                                            ></Col>
                                          ) : null}
                                        </>
                                      );
                                    })}
                                  </>
                                ) : null}
                              </Row>
                            );
                          })}
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </Col>
                <Col sm={12} className="mt-3 mx-0 d-flex justify-content-between">
                  {Number(role) === userRoles.ADMIN ? null : (
                    <Button
                      style={{ color: "white", backgroundColor: "#FF9F43", border: "none" }}
                      className="px-5 py-2"
                      onClick={onSaveHandler}
                    >
                      Save
                    </Button>
                  )}

                  <Button color="primary" className=" px-5 py-2" type="submit">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>

            <StartSurveyModal toggleModal={toggleModal} isOpen={open} />
          </>
        )}
      </div>
    </>
  );
}

export default GovernmentAddSurvey;
