import moment from "moment";
import { Button } from "reactstrap";
import { Badge } from "reactstrap";
import converter from "number-to-words";

export const getColumns = (index, onClickViewHandler) => {
  return [
    {
      Header: "id",
      accessor: "id",
      disableFilters: true,
      disableSortBy: true,
      Cell: ({ row }) => {
        return `${index + row.index + 1}`;
      }
    },
    {
      Header: "Date",
      accessor: "created_at",
      Cell: ({ row }) => {
        return <span>{moment(`${row.original.created_at}`).format("MM-DD-YYYY")}</span>;
      }
    },
    {
      Header: "industry",
      accessor: "industries"
    },
    {
      Header: "Number",
      accessor: "attempt_number",
      Cell: ({ row }) => {
        return (
          <Badge
            color="primary"
            style={{
              color: `${row.original?.attempt_number % 2 === 0 ? "#1273EB" : "#00CFE8"}`,
              backgroundColor: `${row.original?.attempt_number % 2 === 0 ? "#E7F1FD" : "#E6FAFD"}`
            }}
            className="text-capitalize"
            pill
          >
            {converter.toWordsOrdinal(row.original?.attempt_number)}
          </Badge>
        );
      }
    },
    {
      Header: "Firm",
      accessor: "company"
    },
    {
      Header: "Completed by",
      accessor: "first_name",
      Cell: ({ row }) => {
        return (
          <span>
            {row?.original?.first_name} {row?.original?.last_name}
          </span>
        );
      }
    },
    {
      Header: "Score",
      accessor: "total_score"
    },
    {
      Header: "View analysis",
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <span>
            <Button
              size="sm"
              color="primary"
              outline
              onClick={() => onClickViewHandler(row?.original?.id)}
            >
              <i className="far fa-file-alt mr-2" />
              view
            </Button>
          </span>
        );
      }
    },
    {
      Header: "Type",
      accessor: "role_id",
      Cell: ({ cell: { value } }) => {
        if (value === 3) {
          return (
            <Badge pill style={{ color: "#EA5455", backgroundColor: "#FDEEEE" }}>
              Government
            </Badge>
          );
        } else value === 4;
        {
          return (
            <Badge pill style={{ color: "#1273EB", backgroundColor: "#E7F1FD" }}>
              Self-Service
            </Badge>
          );
        }
      }
    }
  ];
};
